$(document).ready(function () {
    // Set background images
    if ($('.bgimage img').length > 0) {
        processBackgroundImages();
    }
});

$(document).ajaxStop(function() {
    changeTranslations();
});


var processBackgroundImages = function () {
    $('.bgimage').each(function () {
        var src = $(this).find('img').attr('src');
        if (src) {
            $(this).css('background-image', 'url("' + src + '")');
        }
    });
};

// ajaxstop
function changeTranslations(){
    var retrievepassword = $('#lbl_usernameretrievepassword');
    var retrievedmsg = $(".loginContainer #lbl_retrievedmsg");
    if (retrievepassword){
        retrievepassword.text("Vul hieronder uw gebruikersnaam of emailadres in.");
    }
    if (retrievedmsg){
        retrievedmsg.text("Wanneer uw gegevens bij ons bekend zijn ontvangt u een link om uw wachtwoord te wijzigen.")
    }
}